import type { THierachicalMenuItem, TMenuItem } from "@/types/graphql/menus"

type TGenericObject = Record<string, THierachicalMenuItem[]>

export function mapMenuItemsChildren(
  data: TMenuItem[]
): THierachicalMenuItem[] {
  let tree: THierachicalMenuItem[] = []
  let childrenOf: TGenericObject = {}

  data.forEach((item) => {
    let newItem: THierachicalMenuItem = { ...item, children: [], depth: 0 }

    let { id, parentId = 0 } = newItem

    childrenOf[id] = childrenOf[id] || []

    newItem.children = childrenOf[id]

    parentId
      ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
      : tree.push(newItem)
  })

  setDepth(tree)

  return tree
}

function setDepth(
  tree: THierachicalMenuItem[],
  depth = 0
): THierachicalMenuItem[] {
  tree.forEach((item) => {
    item.depth = depth

    if (item.children) {
      setDepth(item.children, depth + 1)
    }
  })

  return tree
}
