export default function chevronRight({ className = "" }) {
  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        d="M9.22473 8L8.64454 7.32176L2.36875 0L0.775299 1.35649L6.4709 8L0.775299 14.6435L2.36875 16L8.64454 8.67823L9.22473 8Z"
        fill="currentColor"
      />
    </svg>
  )
}
