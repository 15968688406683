export default function Plus({ className = "" }) {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 0H7V7H0V9H7V16H9V9H16V7H9V0Z"
        fill="currentColor"
      />
    </svg>
  )
}
