import { Menu } from "@/components/footer/menu"
import { Container } from "@/styledComponents/content"
import { Address } from "./address"
import { Contact } from "./contact"
import { SecondaryMenu } from "./secondaryMenu"
import { Visit } from "./visit"

export function Footer() {
  return (
    <footer className="py-8 sm:py-10 md:py-12 lg:py-14 xl:py-16 text-blue-dark bg-gray">
      <Container className="flex flex-col flex-wrap items-start mb-6 sm:mb-8 xl:mb-10 sm:flex-row">
        <div className="sm:w-1/2 lg:w-1/4">
          <Menu />
        </div>
        <div className="mt-8 sm:mt-0 sm:w-1/2 lg:w-1/4">
          <Contact />
        </div>
        <div className="mt-8 sm:mt-6 lg:mt-0 sm:w-1/2 lg:w-1/4">
          <Address />
        </div>
        <div className="mt-8 sm:mt-6 lg:mt-0 sm:w-1/2 lg:w-1/4">
          <Visit />
        </div>
      </Container>
      <hr className="border-gray-border" />
      <Container>
        <div className="justify-between mt-6 sm:mt-8 xl:mt-10 sm:flex sm:gap-x-6 lg:items-center lg:flex-row lg:w-full">
          <p>
            © {new Date().getFullYear()} Swedish Financial Benchmark Facility
            all rights reserved.
          </p>
          <SecondaryMenu />
        </div>
      </Container>
    </footer>
  )
}
