import type { TMenuItems } from "@/types/graphql/menus"
import type { TPageComponent } from "@/types/graphql/pageComponents"
import type {
  TThemeSettingsGroup,
  TThemeSettingsPage
} from "@/types/graphql/pathData"
import Script from "next/script"
import * as React from "react"
import { Tracking } from "../tracking"

type State = TMenuItems &
  TThemeSettingsPage & { pageComponents?: TPageComponent[] }

type ThemeProviderProps = {
  children: React.ReactNode
  initialState: State
  databaseId?: number | undefined
}

const ThemeStateContext = React.createContext<State | undefined>(undefined)

function ThemeProvider({
  children,
  initialState,
  databaseId
}: ThemeProviderProps) {
  return (
    <ThemeStateContext.Provider value={initialState}>
      <Script id="databaseId">{`window.postId = ${databaseId}`}</Script>
      <Tracking />
      {children}
    </ThemeStateContext.Provider>
  )
}

function useThemeData(): State {
  const state = React.useContext(ThemeStateContext)

  if (state === undefined) {
    throw new Error("useThemeData must be used within a ThemeProvider")
  }

  return state
}

function useThemeSettingsGroup(): TThemeSettingsGroup {
  const state = React.useContext(ThemeStateContext)

  if (state === undefined) {
    throw new Error("useThemeData must be used within a ThemeProvider")
  }

  return state.themeSettingsPage.themeSettingsGroup
}

export { ThemeProvider, useThemeData, useThemeSettingsGroup }
