import type { TIconProps } from "./types"

export default function MenuIcon({ className }: TIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path d="m7 10 5 5 5-5H7Z" fill="currentColor" />
    </svg>
  )
}
