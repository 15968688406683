import styled from "styled-components"
import tw from "twin.macro"

type ButtonProps = {
  size?: "small"
  variant?: "secondary"
}

export const buttonClasses = tw``

export let Button = styled.button<ButtonProps>`
  ${buttonClasses}

  ${({ size }) => (size === "small" ? tw`py-3` : tw`py-5`)}
`
