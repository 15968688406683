import { InputText, Label } from "@/components/styled/form"
import useFieldValidationState from "@/lib/hooks/useFieldValidationState"
import { useFormContext } from "react-hook-form"
import parse from "html-react-parser"
import { ErrorMessage } from "./errorMessage"

export type TextFieldProps = {
  id: string
  label: string
  maxLength?: number
  required: boolean
  placeholder?: string
  description?: string
  descriptionOnTop?: boolean
  defaultValue?: string
  enableAutocomplete?: boolean
  autocompleteAttribute?: string
}

export default function TextField({
  id,
  label,
  maxLength,
  required,
  placeholder,
  description,
  descriptionOnTop,
  defaultValue,
  enableAutocomplete,
  autocompleteAttribute,
  ...props
}: TextFieldProps & React.InputHTMLAttributes<HTMLInputElement>) {
  const { register } = useFormContext()
  const fieldValidationState = useFieldValidationState(id)

  return (
    <div>
      <Label htmlFor={id} isRequired={required}>
        {label}
      </Label>

      {description && descriptionOnTop && (
        <p className="inline-block mb-2 text-xs">{parse(description)}</p>
      )}

      <InputText
        fieldValidationState={fieldValidationState}
        register={register(id, {
          maxLength: maxLength ? maxLength : undefined,
          required: required
        })}
        type="text"
        id={id}
        placeholder={placeholder}
        defaultValue={defaultValue}
        autoComplete={(enableAutocomplete && autocompleteAttribute) || ""}
        {...props}
      />
      <ErrorMessage name={id} />

      {description && !descriptionOnTop && (
        <p className="inline-block mt-2 text-xs">{parse(description)}</p>
      )}
    </div>
  )
}
