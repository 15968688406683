import { useEffect } from "react"
import { Menu } from "@/components/header/menu"
import { OffCanvas } from "@/components/header/offCanvas"
import { Logo } from "@/components/logo"
import { useMediaQuery } from "@/lib/hooks/useMediaQuery"
import cx from "classnames"
import Link from "next/link"
import { useState } from "react"
import { mapMenuItemsChildren } from "utils/mapMenuItemsChildren"
import { ButtonUnstyled } from "../buttons"
import { useThemeData } from "../context/theme"
import { Overlay } from "./overlay"
import { SearchForm } from "./searchForm"
import tailwindConfig from "tailwind.config"
import Icon from "../icons/Icon"
import { useRouter } from "next/router"
import MyAccount from "./myAccount"
import { AdminBar } from "../adminBar"

export type TDialogs = string | ""

export const MenuIndicator = ({
  className,
  iconClassName
}: {
  className?: string
  iconClassName?: string
}) => {
  return (
    <div
      role="presentation"
      className={cx(
        "absolute bottom-0 inset-x-0 flex items-center justify-center text-blue pointer-events-none",
        className
      )}>
      <Icon name="menuIndicator" className={iconClassName} />
    </div>
  )
}

const Header = () => {
  const { primaryMenuItems, secondaryMenuItems } = useThemeData()
  const { asPath } = useRouter()
  const isDesktop = useMediaQuery(
    `(min-width: ${tailwindConfig.theme.screens.lg})`
  )
  const hierachicalMenuItems = mapMenuItemsChildren(primaryMenuItems.nodes)
  const hierachicalSecondaryMenuItems = mapMenuItemsChildren(
    secondaryMenuItems.nodes
  )

  const [openDialog, setOpenDialog] = useState<TDialogs>("")

  const offCanvasIsOpen = openDialog === "offCanvas"
  const searchIsOpen = openDialog === "search"
  const accountIsOpen = openDialog === "myaccount"

  const isOpen = offCanvasIsOpen || searchIsOpen || accountIsOpen

  const headerClasses = cx(
    "flex-col items-center w-full sticky top-0 bg-white z-40"
  )

  useEffect(() => {
    document.body.classList.toggle("overflow-hidden", isOpen)
    return () => {
      document.body.classList.remove("overflow-hidden")
    }
  }, [isOpen])

  useEffect(() => {
    setOpenDialog("")
  }, [asPath])

  return (
    <>
      <header className={headerClasses}>
        <AdminBar />
        <div className="flex w-full h-full px-4 max-w-none h-header">
          <div className="flex items-center justify-between w-full h-full">
            <Link href="/">
              <a className="mr-auto sm:mr-0">
                <Logo />
              </a>
            </Link>
            {isDesktop && (
              <Menu
                setOpenDialog={setOpenDialog}
                openDialog={openDialog}
                menuItems={hierachicalMenuItems}
              />
            )}
            <ul className="flex h-full space-x-6">
              <li className="relative flex items-center">
                <MyAccount
                  isOpen={accountIsOpen}
                  setOpenDialog={setOpenDialog}
                />
                {accountIsOpen ? <MenuIndicator /> : null}
              </li>
              <li className="relative flex items-center">
                <SearchForm
                  isOpen={openDialog === "search"}
                  setOpenDialog={setOpenDialog}
                />
                {searchIsOpen ? <MenuIndicator /> : null}
              </li>
              {!isDesktop && (
                <li className="relative flex items-center">
                  <ButtonUnstyled
                    className={cx("", {
                      "text-blue": offCanvasIsOpen
                    })}
                    iconName={offCanvasIsOpen ? "close" : "menu"}
                    onClick={() =>
                      offCanvasIsOpen
                        ? setOpenDialog("")
                        : setOpenDialog("offCanvas")
                    }>
                    <span className="sr-only">
                      {offCanvasIsOpen ? "Close menu" : "Open menu"}
                    </span>
                  </ButtonUnstyled>
                  {offCanvasIsOpen ? <MenuIndicator /> : null}
                </li>
              )}
            </ul>
          </div>
        </div>
      </header>
      <OffCanvas
        menuItems={hierachicalMenuItems}
        secondaryMenuItems={hierachicalSecondaryMenuItems}
        isOpen={offCanvasIsOpen}
        setOpenDialog={setOpenDialog}
      />
      <Overlay isOpen={isOpen} setOpenDialog={setOpenDialog} />
    </>
  )
}

export default Header
