import { useThemeSettingsGroup } from "@/components/context/theme"
import parse from "html-react-parser"

export function Visit() {
  const { visit } = useThemeSettingsGroup()

  return (
    <div>
      <p className="mb-2 text-xl font-semibold">{visit.visitTitle}</p>
      <address className="not-italic">
        {visit.visitText && (
          <div className="mt-2">{parse(visit.visitText)}</div>
        )}
      </address>
    </div>
  )
}
