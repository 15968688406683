import { forwardRef } from "react"
import { Button } from "../buttons"
import cx from "classnames"
import { useMediaQuery } from "@/lib/hooks/useMediaQuery"
import tailwindConfig from "tailwind.config"

type Props = {
  handleOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  className?: string
  defaultValue?: string
}

export const SearchFormComponent = forwardRef<HTMLInputElement, Props>(
  ({ placeholder, className, defaultValue, handleOnChange }, ref) => {
    const searchFormClasses = cx(
      "flex items-center w-full p-6 md:p-8 ml-auto bg-blue",
      className
    )

    const isDesktop = useMediaQuery(
      `(min-width: ${tailwindConfig.theme.screens.md})`
    )

    return (
      <div className={searchFormClasses}>
        <input
          type="text"
          id="search"
          name="search"
          className="flex w-full px-4 py-2 mr-2 text-base text-black placeholder-gray-300 rounded-none outline-none h-14"
          placeholder={placeholder}
          ref={ref}
          onChange={handleOnChange}
          defaultValue={defaultValue}
        />
        <Button
          iconName={isDesktop ? "search" : undefined}
          iconPosition="left"
          type="submit"
          className="h-14">
          Search
        </Button>
      </div>
    )
  }
)

SearchFormComponent.displayName = "SearchFormComponent"

export default SearchFormComponent
