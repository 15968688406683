import type { TIconProps } from "./types"

export default function MenuIcon({ className }: TIconProps) {
  return (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path fill="currentColor" d="M0 0h24v2H0zM0 8h24v2H0zM0 16h24v2H0z" />
    </svg>
  )
}
