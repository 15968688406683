module.exports = {
  content: [
    "./pages/**/*.{js,jsx,tsx,ts}",
    "./components/**/*.{js,jsx,tsx,ts}",
    "./utils/**/*.{js,jsx,tsx,ts}"
  ],
  safelist: ["inline-block", "!no-underline"],
  important: "#app",
  theme: {
    screens: {
      sm: "640px",
      md: "768px",
      lg: "1024px",
      xl: "1440px"
    },
    colors: {
      transparent: "transparent",
      red: {
        DEFAULT: "#CE0025",
        light: "#FBDDE3"
      },
      green: "#DDDDCE",
      orange: "#E0D4CE",
      black: "#000",
      white: "#fff",
      theme: {
        link: "#14477C"
      },
      blue: {
        100: "#E9F3FB",
        200: "#C4D9E9",
        300: "#9FBED6",
        400: "#AEC1D6",
        500: "#6A839F",
        550: "#6090B5",
        600: "#3F78B4",
        700: "#0A335F",
        dark: "#0A233D",
        DEFAULT: "#14477C"
      },
      yellow: {
        400: "#F8D75C",
        500: "#E0B71D",
        DEFAULT: "#F5C925"
      },
      gray: {
        100: "#F2F2F2",
        200: "#E4E4E4",
        300: "#7F7F7F",
        400: "#404040",
        500: "#404040",
        600: "#202020",
        700: "#313131",
        border: "#D0D6DF",
        DEFAULT: "#F1F3F6"
      },
      green: {
        light: "#EFFFEF",
        DEFAULT: "#3C8239"
      },
      input: {
        bg: "#F7F8FB",
        border: "#D0D6DF"
      }
    },
    fontFamily: {
      sans: [
        "Inter",
        "ui-sans-serif",
        "system-ui",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        '"Noto Sans"',
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"'
      ]
    },
    extend: {
      container: {
        padding: "1rem",
        center: true
      },
      spacing: {
        header: "var(--header-height)",
        "header-offset": "var(--header-offset)"
      }
    }
  },
  plugins: [
    require("@tailwindcss/line-clamp"),
    require("@tailwindcss/aspect-ratio")
  ]
}
