import type { TMenuItem } from "@/types/graphql/menus"
import Link from "next/link"
import { useThemeData } from "../context/theme"

export function SecondaryMenu() {
  const { secondaryMenuItems } = useThemeData()

  return (
    <nav className="flex-shrink-0 mt-4 sm:mt-0 lg:h-full">
      <ul className="flex">
        {secondaryMenuItems.nodes.map((item: TMenuItem) => (
          <li key={item.id}>
            <Link href={item.path}>
              <a className="pr-4 underline">{item.label}</a>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}
