import useAuth from "@/lib/hooks/useAuth"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import Icon from "./icons/Icon"

declare global {
  interface Window {
    postId: string
  }
}

export function AdminBar() {
  const { showAdminBar } = useAuth()
  const [postId, setPostId] = useState<string>()
  const router = useRouter()

  useEffect(() => {
    setPostId(window.postId)
  }, [router, setPostId])

  useEffect(() => {
    document.body.classList.toggle("admin-bar", showAdminBar)
    return () => {
      document.body.classList.remove("admin-bar")
    }
  }, [showAdminBar])

  if (!showAdminBar) {
    return null
  }

  return (
    <div className="flex items-center w-full h-8 px-4 text-sm text-white bg-black">
      <ul className="flex gap-4">
        <li>
          <a
            className="flex items-center hover:underline"
            href={`${process.env.NEXT_PUBLIC_API_URL?.replace(
              "/wp/graphql",
              ""
            )}/wp/wp-admin`}>
            <Icon name="home" className="w-4 h-4 mr-1" />
            Admin
          </a>
        </li>
        {window.postId && (
          <li>
            <a
              className="flex items-center hover:underline"
              href={`${process.env.NEXT_PUBLIC_API_URL?.replace(
                "/wp/graphql",
                ""
              )}/wp/wp-admin/post.php?post=${postId}&action=edit`}>
              <Icon name="edit" className="w-4 h-4 mr-1" />
              Edit page
            </a>
          </li>
        )}
      </ul>
    </div>
  )
}
