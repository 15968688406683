import type { TIconProps } from "./types"

export default function FacebookIcon({ className }: TIconProps) {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.652 16H8.958V7.999H11.164L11.456 5.242H8.957L8.961 3.862C8.961 3.143 9.029 2.757 10.061 2.757H11.439V0H9.234C6.584 0 5.652 1.338 5.652 3.587V5.242H4V7.999H5.651V16H5.652Z"
        fill="currentColor"
      />
    </svg>
  )
}
