import type { THierachicalMenuItem } from "@/types/graphql/menus"
import Link from "next/link"
import { mapMenuItemsChildren } from "utils/mapMenuItemsChildren"
import { useThemeData, useThemeSettingsGroup } from "../context/theme"

export function Menu() {
  const { footerMenuItems } = useThemeData()
  const { navTitle } = useThemeSettingsGroup()
  const menuItems = mapMenuItemsChildren(footerMenuItems.nodes)

  return (
    <nav>
      <p className="mb-2 text-xl font-semibold">{navTitle}</p>
      <ul>
        {menuItems.map((item, index) => (
          <MenuItem key={index} item={item} />
        ))}
      </ul>
    </nav>
  )
}

function MenuItem({ item }: { item: THierachicalMenuItem }) {
  return (
    <li key={item.id} className="mt-2">
      <Link href={item.path}>
        <a className="underline">{item.label}</a>
      </Link>
    </li>
  )
}
