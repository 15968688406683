import * as React from "react"

export function useMediaQuery(query: string): boolean {
  const [matches, setMatches] = React.useState<boolean>(false)

  React.useEffect(() => {
    const media = window.matchMedia(query)

    if (media.matches !== matches) {
      setMatches(media.matches)
    }

    const listener = () => setMatches(media.matches)

    try {
      media.addEventListener("change", listener)
    } catch (error) {
      media.addListener(listener)
    }

    return () => {
      try {
        media.removeEventListener("change", listener)
      } catch (error) {
        media.removeListener(listener)
      }
    }
  }, [matches, query])

  return matches
}
