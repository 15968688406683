import type { TIconProps } from "./types"

export default function TwitterIcon({ className }: TIconProps) {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)">
        <path
          d="M16.001 3.537a6.557 6.557 0 0 1-1.884.517 3.291 3.291 0 0 0 1.442-1.815 6.622 6.622 0 0 1-2.085.797 3.282 3.282 0 0 0-5.593 2.993 9.318 9.318 0 0 1-6.764-3.43A3.28 3.28 0 0 0 2.131 6.98a3.276 3.276 0 0 1-1.486-.412v.041a3.286 3.286 0 0 0 2.633 3.218 3.339 3.339 0 0 1-.866.115c-.212 0-.417-.021-.618-.061a3.284 3.284 0 0 0 3.065 2.28 6.583 6.583 0 0 1-4.076 1.402c-.265 0-.526-.016-.783-.045a9.274 9.274 0 0 0 5.031 1.477c6.038 0 9.338-5.001 9.338-9.338l-.011-.425a6.554 6.554 0 0 0 1.643-1.695Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
