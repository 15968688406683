import type { TIconProps } from "./types"

export default function LinkedinIcon({ className }: TIconProps) {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.816 0H1.181C.53 0 0 .517 0 1.154v13.692C0 15.482.53 16 1.181 16h13.635c.653 0 1.184-.517 1.184-1.154V1.154C16 .517 15.468 0 14.816 0ZM4.746 13.634H2.372V5.998h2.373l.001 7.636Zm-1.187-8.68a1.376 1.376 0 1 1 0-2.752 1.376 1.376 0 0 1 0 2.752Zm10.075 8.68h-2.371V9.921c0-.886-.016-2.025-1.233-2.025-1.235 0-1.424.965-1.424 1.961v3.778H6.234V5.998H8.51v1.043h.031c.317-.6 1.09-1.234 2.246-1.234 2.403 0 2.846 1.582 2.846 3.638v4.189h.001Z"
        fill="currentColor"
      />
    </svg>
  )
}
