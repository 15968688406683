import type { TIconProps } from "./types"

export default function YoutubeIcon({ className }: TIconProps) {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)">
        <path
          d="M13.292 2.269H2.708A2.698 2.698 0 0 0 0 4.958v5.622a2.698 2.698 0 0 0 2.708 2.689h10.585a2.699 2.699 0 0 0 2.708-2.689V4.958a2.7 2.7 0 0 0-2.709-2.689Zm-2.158 5.608-4.8 2.444a.123.123 0 0 1-.18-.108V5.325c0-.042.023-.082.06-.104a.125.125 0 0 1 .12-.004l4.8 2.444a.124.124 0 0 1 .066.109.124.124 0 0 1-.067.109v-.002h.001Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
