type Sizes = {
  mobile: string
  tablet: string
  desktop: string
}

export function mapTailwindColumns(
  sizes: Sizes,
  numberOfColumns: number
): string {
  let classes: string[] = []

  Object.keys(sizes).map((device) => {
    if (device === "mobile") {
      if (parseInt(sizes[device]) > numberOfColumns) {
        classes.push(`grid-cols-${numberOfColumns}`)
      } else {
        if (sizes[device] === "1") {
          classes.push("grid-cols-1")
        }

        if (sizes[device] === "2") {
          classes.push("grid-cols-2")
        }
      }
    }

    if (device === "tablet") {
      if (parseInt(sizes[device]) > numberOfColumns) {
        classes.push(`md:grid-cols-${numberOfColumns}`)
      } else {
        if (sizes[device] === "1") {
          classes.push("md:grid-cols-1")
        }

        if (sizes[device] === "2") {
          classes.push("md:grid-cols-2")
        }

        if (sizes[device] === "3") {
          classes.push("md:grid-cols-3")
        }

        if (sizes[device] === "4") {
          classes.push("md:grid-cols-4")
        }
      }
    }

    if (device === "desktop") {
      if (parseInt(sizes[device]) > numberOfColumns) {
        classes.push(`lg:grid-cols-${numberOfColumns}`)
      } else {
        if (sizes[device] === "1") {
          classes.push("lg:grid-cols-1")
        }

        if (sizes[device] === "2") {
          classes.push("lg:grid-cols-2")
        }

        if (sizes[device] === "3") {
          classes.push("lg:grid-cols-3")
        }

        if (sizes[device] === "4") {
          classes.push("lg:grid-cols-4")
        }
      }
    }
  })

  return classes.join(" ")
}

export function mapTailwindColors(color: string): string {
  switch (color) {
    // BG COLORS
    case "black":
      return "bg-black"
    case "white":
      return "bg-white"
    case "gray":
      return "bg-gray"
    case "blue":
      return "bg-blue-dark"
    default:
      return ""
  }
}
