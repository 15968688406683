import CheckIcon from "./check"
import ChevronDownIcon from "./chevronDown"
import chevronLeft from "./chevronLeft"
import chevronRight from "./chevronRight"
import ChevronUpIcon from "./chevronUp"
import CloseIcon from "./close"
import DownloadIcon from "./download"
import ErrorIcon from "./error"
import FacebookIcon from "./facebook"
import InstagramIcon from "./instagram"
import LinkedinIcon from "./linkedin"
import MenuIcon from "./menu"
import MinusIcon from "./minus"
import PersonIcon from "./person"
import PersonSolidIcon from "./personSolid"
import PinIcon from "./pin"
import Plus from "./plus"
import SearchIcon from "./search"
import TimeIcon from "./time"
import TwitterIcon from "./twitter"
import YoutubeIcon from "./youtube"
import MenuArrow from "./menuArrow"
import MenuIndicator from "./menuIndicator"
import EditIcon from "./edit"
import HomeIcon from "./home"
import SuccessIcon from "./success"

const Icons = {
  check: CheckIcon,
  chevronDown: ChevronDownIcon,
  chevronLeft: chevronLeft,
  chevronRight: chevronRight,
  chevronUp: ChevronUpIcon,
  close: CloseIcon,
  download: DownloadIcon,
  error: ErrorIcon,
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  linkedin: LinkedinIcon,
  menu: MenuIcon,
  minus: MinusIcon,
  person: PersonIcon,
  personSolid: PersonSolidIcon,
  pin: PinIcon,
  plus: Plus,
  search: SearchIcon,
  time: TimeIcon,
  twitter: TwitterIcon,
  youtube: YoutubeIcon,
  menuArrow: MenuArrow,
  menuIndicator: MenuIndicator,
  edit: EditIcon,
  home: HomeIcon,
  success: SuccessIcon
}

export type TIcons = keyof typeof Icons

type Props = {
  name: TIcons
  className?: string
  role?: string
}

const Icon = ({ name, className }: Props) => {
  const Icon = Icons[name] ?? null

  if (!Icon) {
    return null
  }

  return <Icon className={className} />
}

export default Icon
