export default function chevronLeft({ className = "" }) {
  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        d="M0.775289 8L1.35547 8.67824L7.63127 16L9.22472 14.6435L3.52912 8L9.22472 1.35649L7.63127 -1.39304e-07L1.35547 7.32177L0.775289 8Z"
        fill="currentColor"
      />
    </svg>
  )
}
