import type { TIconProps } from "./types"

export default function InstagramIcon({ className }: TIconProps) {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.546,0C13.998,0,16,2.004,16,4.459v7.082C16,13.996,13.998,16,11.546,16H4.454
	C2.002,15.999,0,13.995,0,11.541V4.459C0,2.004,2.002,0,4.472,0H11.546z M8.009,4.777C6.232,4.777,4.79,6.235,4.79,8
	c0,1.779,1.442,3.222,3.219,3.222S11.228,9.778,11.228,8C11.228,6.235,9.786,4.777,8.009,4.777z M12.257,4.852
	c0.589,0,1.067-0.478,1.067-1.068s-0.477-1.068-1.067-1.068s-1.067,0.478-1.067,1.068S11.668,4.852,12.257,4.852z"
        fill="currentColor"
      />
    </svg>
  )
}
