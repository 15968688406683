import { useRouter } from "next/dist/client/router"
import Script from "next/script"
import { useEffect, useState } from "react"
import { useThemeSettingsGroup } from "./context/theme"

declare global {
  interface Window {
    dataLayer: {
      event: string
      path: string
    }[]
  }
}

export function Tracking() {
  let { cookiebotId, gtmId } = useThemeSettingsGroup()
  let [gtmCode, setGtmCode] = useState<string>()
  let [googleConsentCode, setGoogleConsentCode] = useState<string>()
  let router = useRouter()

  useEffect(() => {
    if (!gtmId) {
      return
    }

    window.dataLayer = window.dataLayer || []

    if (gtmId) {
      setGtmCode(
        `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${gtmId}');`
      )
    }

    setGoogleConsentCode(`
      window.dataLayer = window.dataLayer || [];
      function gtag() {
          dataLayer.push(arguments);
      }
      gtag("consent", "default", {
          ad_storage: "denied",
          analytics_storage: "denied",
          functionality_storage: "denied",
          personalization_storage: "denied",
          security_storage: "granted",
          wait_for_update: 2000,
      });
      gtag("set", "ads_data_redaction", true);`)
  }, [gtmId, cookiebotId])

  useEffect(() => {
    const trackPageview = (path: string) => {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "nextRoute",
        path: path
      })
    }
    router.events.on("routeChangeComplete", trackPageview)
    return () => {
      router.events.off("routeChangeComplete", trackPageview)
    }
  }, [router.events])

  return (
    <>
      {cookiebotId && (
        <Script
          id="Cookiebot"
          data-cookieconsent="ignore"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid={cookiebotId}
        />
      )}
      {googleConsentCode && (
        <Script
          id="googleConsentCode"
          data-cookieconsent="ignore"
          dangerouslySetInnerHTML={{
            __html: googleConsentCode || ""
          }}
        />
      )}
      {gtmCode && (
        <Script
          id="gtmCode"
          data-cookieconsent="ignore"
          dangerouslySetInnerHTML={{
            __html: gtmCode || ""
          }}
        />
      )}
    </>
  )
}
