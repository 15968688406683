import { ErrorMessage as HookFormErrorMessage } from "@hookform/error-message"
import { useFormContext } from "react-hook-form"

const DEFAULT_REQUIRED_ERROR_MESSAGE = "Required field"

export function ErrorMessage(
  props: React.ComponentProps<typeof HookFormErrorMessage>
) {
  const { formState } = useFormContext()

  return (
    <HookFormErrorMessage
      {...props}
      errors={formState.errors}
      render={({ message }) => (
        <span className="block mt-1 text-sm text-red">
          {message || DEFAULT_REQUIRED_ERROR_MESSAGE}
        </span>
      )}
    />
  )
}
