import type { TFieldValidationState } from "@/lib/hooks/useFieldValidationState"
import React, { useEffect, useRef, useState } from "react"
import { UseFormRegisterReturn, useWatch } from "react-hook-form"
import styled from "styled-components"
import tw from "twin.macro"
import CheckIcon from "../icons/check"
import ErrorIcon from "../icons/error"
import { Button } from "./buttons"

type InputType = {
  fieldValidationState?: TFieldValidationState
  register?: UseFormRegisterReturn
}

const inputClasses =
  "w-full px-4 py-3 border placeholder-gray-700/70 outline-none disabled:cursor-not-allowed disabled:opacity-50 text-black rounded-none"

export const Input = styled.input<InputType>`
  ${tw`${inputClasses}`}

  .bg-blue & {
    ${tw`bg-white border-white`}
  }

  .bg-gray & {
    ${tw`bg-white`}
  }

  ${({ fieldValidationState }) => {
    if (fieldValidationState === "valid") {
      return tw`border-green !bg-green-light pr-11`
    }

    if (fieldValidationState === "invalid") {
      return tw`border-red !bg-red-light text-red pr-11`
    }

    return tw`bg-input-bg border-input-border`
  }}
`

export const Select = styled.select`
  ${tw`${inputClasses} appearance-none`}
`

export const StyledInput = styled(Input)<InputType>``
const StyledTextarea = styled(Input).attrs({ as: "textarea", rows: 5 })`
  ${tw`block`}
`

export function InputText({
  register,
  ...props
}: React.InputHTMLAttributes<HTMLInputElement> & InputType) {
  return (
    <div className="relative">
      <StyledInput {...register} {...props} />
      {props.fieldValidationState === "valid" && (
        <CheckIcon className="text-green absolute inset-y-0 top-[13px] right-4 w-6 h-6" />
      )}
      {props.fieldValidationState === "invalid" && (
        <ErrorIcon className="text-red absolute inset-y-0 top-[13px] right-4 w-6 h-6" />
      )}
    </div>
  )
}

export function Textarea({
  children,
  register,
  ...props
}: React.TextareaHTMLAttributes<HTMLTextAreaElement> & InputType) {
  return (
    <div className="relative">
      <StyledTextarea {...register} {...props} as="textarea" rows={5} />
      {props.fieldValidationState === "valid" && (
        <CheckIcon className="text-green absolute inset-y-0 top-[13px] right-4 w-6 h-6" />
      )}
      {props.fieldValidationState === "invalid" && (
        <ErrorIcon className="text-red absolute inset-y-0 top-[13px] right-4 w-6 h-6" />
      )}
      {children}
    </div>
  )
}

type LabelType = {
  isRequired?: boolean
}

let StyledLabel = styled.label<LabelType>`
  ${tw`block mb-1 text-sm font-bold cursor-pointer`}

  *[disabled] & {
    ${tw`opacity-50`}
  }
`

export function Label({
  children,
  ...props
}: React.LabelHTMLAttributes<HTMLLabelElement> & LabelType) {
  return (
    <>
      <StyledLabel {...props}>
        <>
          {children}
          {props.isRequired && (
            <>
              <span className="text-red">*</span>
            </>
          )}
        </>
      </StyledLabel>
    </>
  )
}

let StyledInputCheckbox = styled.input.attrs({ type: "checkbox" })`
  ${tw`sr-only`}
`
export let InputCheckboxLabel = styled.label`
  ${tw`cursor-pointer before:inline-block pl-10 before:bg-gray-300 before:relative before:top-[.375em] flex items-center`}
`

type CheckboxType = {
  register?: UseFormRegisterReturn
}

export function InputCheckbox({
  children,
  register,
  color,
  ...props
}: React.InputHTMLAttributes<HTMLInputElement> &
  CheckboxType &
  StyledLabelProps) {
  const bg = color === "white" ? "bg-white" : "bg-[#F7F8FB]"
  return (
    <div className="relative flex items-center select-none">
      <StyledInputCheckbox {...register} {...props} type="checkbox" />

      <span
        className={`absolute flex items-center justify-center w-6 h-6 text-transparent transition-colors ${bg} border border-gray-border pointer-events-none peer-checked:text-blue-dark peer-checked:bg-yellow peer-checked:border-yellow`}>
        <CheckIcon className="w-6 h-6" />
      </span>

      {children}
    </div>
  )
}

type StyledLabelProps = {
  color?: "gray" | "white"
}

export let InputRadioLabel = styled.label<StyledLabelProps>`
  ${tw`
    cursor-pointer transition-colors flex items-center select-none
    before:inline-block before:w-6 before:h-6 before:border-[1px] peer-checked:before:border-[6px] before:border-gray-border before:rounded-full before:mr-4
    peer-checked:before:border-yellow peer-checked:before:bg-black
  `}

  ${({ color }) =>
    color === "white" ? tw`before:bg-white` : tw`before:bg-gray-100`}
`

let StyledInputRadio = styled.input.attrs({ type: "radio" })`
  ${tw`sr-only`}
`

type RadioType = {
  register?: UseFormRegisterReturn
}

export function InputRadio({
  children,
  register,
  ...props
}: React.InputHTMLAttributes<HTMLInputElement> & RadioType) {
  return (
    <div className="flex">
      <StyledInputRadio {...register} {...props} type="radio" />
      {children}
    </div>
  )
}

type InputFileProps = React.InputHTMLAttributes<HTMLInputElement> & {
  name: string
  register: UseFormRegisterReturn
  defaultValue?: string
}

export function InputFile({
  name,
  register,
  defaultValue,
  ...props
}: InputFileProps) {
  let value = useWatch<Record<typeof name, FileList>>({
    name
  })
  let [filename, setFilename] = useState("")
  let inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (typeof value !== "object") {
      setFilename("")
      return
    }

    if (!value[0]?.name) {
      setFilename("")
      return
    }

    setFilename(value[0].name)
  }, [value])

  let { ref, ...restRegisterProps } = register

  return (
    <div className="flex">
      <input
        type="file"
        id={name}
        className="sr-only"
        {...restRegisterProps}
        {...props}
        ref={(e) => {
          ref(e)
          inputRef.current = e
        }}
      />
      <StyledInput
        readOnly
        placeholder="Välj fil"
        value={filename || defaultValue || ""}
        className="read-only:cursor-default"
        onClick={() => inputRef.current?.click()}
      />
      <Button
        as="label"
        size="small"
        className="!min-w-min items-center !inline-flex ml-2 cursor-pointer"
        htmlFor={name}>
        Bläddra
      </Button>
    </div>
  )
}
