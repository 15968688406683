import { Dispatch, SetStateAction, useRef } from "react"
import useOnClickOutside from "@/lib/hooks/useOnClickOutside"
import type { TDialogs } from "@/components/header"
import { ButtonUnstyled } from "../buttons"
import cx from "classnames"
import WPLoginForm from "../auth/WPLoginForm"
import Link from "next/link"
import useAuth from "@/lib/hooks/useAuth"
import { useMutation } from "@apollo/client"
import { WP_GET_USER, WP_LOG_OUT } from "@/lib/graphql/mutations"
import { useThemeSettingsGroup } from "../context/theme"

type Props = {
  isOpen: boolean
  setOpenDialog: Dispatch<SetStateAction<TDialogs>>
}

const Menu = () => {
  const [logOut] = useMutation(WP_LOG_OUT, {
    refetchQueries: [{ query: WP_GET_USER }]
  })

  const { stiborPage } = useThemeSettingsGroup()

  return (
    <ul className="space-y-4 font-semibold underline">
      {stiborPage?.uri && stiborPage?.status === "publish" ? (
        <li>
          <Link href={stiborPage.uri}>
            <a>Historical data</a>
          </Link>
        </li>
      ) : null}
      {/* <li>
        <Link href="/">
          <a>My account</a>
        </Link>
      </li> */}
      <li>
        <ButtonUnstyled
          onClick={() => logOut()}
          className="font-semibold underline">
          Logout
        </ButtonUnstyled>
      </li>
    </ul>
  )
}

const MyAccount = ({ isOpen, setOpenDialog }: Props) => {
  const { loggedIn } = useAuth()
  const handleClickOutside = () => {
    if (!isOpen) {
      return
    }
    setOpenDialog("")
  }

  const ref = useRef<HTMLDivElement>(null)

  useOnClickOutside(ref, handleClickOutside)

  return (
    <div ref={ref} className="relative flex items-center h-full">
      <ButtonUnstyled
        iconName={isOpen ? "personSolid" : "person"}
        className={cx({ "text-blue": isOpen })}
        onClick={() =>
          isOpen ? setOpenDialog("") : setOpenDialog("myaccount")
        }>
        <span className="sr-only">
          {isOpen ? "Close my account" : "Open my account"}
        </span>
      </ButtonUnstyled>
      {isOpen && (
        <div className="fixed bg-blue p-8 left-0 right-0 z-40 flex items-center top-header-offset sm:max-w-[22rem] w-full sm:left-auto md:min-w-[320px] max-h-[80vh] text-white overflow-hidden overflow-y-auto">
          {loggedIn ? <Menu /> : <WPLoginForm />}
        </div>
      )}
    </div>
  )
}

export default MyAccount
