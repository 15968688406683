import type { TIconProps } from "./types"

export default function CloseIcon({ className }: TIconProps) {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        fill="currentColor"
        d="m4.222.808 16.97 16.97-1.414 1.415-16.97-16.97z"
      />
      <path
        fill="currentColor"
        d="M2.808 17.778 19.778.808l1.415 1.413L4.222 19.192z"
      />
    </svg>
  )
}
