import type { TIconProps } from "./types"

export default function MenuIcon({ className }: TIconProps) {
  return (
    <svg
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        d="M10 .012C9.692-.357 3.205 7.85 0 12h20L10 .012Z"
        fill="currentColor"
      />
    </svg>
  )
}
