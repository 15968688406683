import { useController } from "react-hook-form"

export type TFieldValidationState = "valid" | "invalid" | "default"

export default function useFieldValidationState(
  name: string
): TFieldValidationState {
  let { fieldState } = useController({ name })

  if (fieldState.error || fieldState.invalid) {
    return "invalid"
  }

  if (fieldState.isTouched && !fieldState.error && !fieldState.invalid) {
    return "valid"
  }

  return "default"
}
