import { InputText, Label } from "@/components/styled/form"
import useFieldValidationState from "@/lib/hooks/useFieldValidationState"
import { useFormContext } from "react-hook-form"
import { ErrorMessage } from "./errorMessage"

export type PasswordFieldProps = {
  id: string
  confirmPassword: boolean
  label: string
  confirmLabel?: string
  maxLength?: number
  required: boolean
  placeholder?: string
  confirmPlaceholder?: string
}

export default function PasswordField({
  id,
  confirmPassword,
  label,
  confirmLabel,
  maxLength,
  required,
  placeholder,
  confirmPlaceholder
}: PasswordFieldProps) {
  const { register, getValues } = useFormContext()
  const fieldValidationState = useFieldValidationState(id)
  const confirmFieldValidationState = useFieldValidationState(`${id}-confirm`)

  if (!confirmPassword) {
    return (
      <div>
        <Label htmlFor={id} isRequired={required}>
          {label}
        </Label>
        <InputText
          fieldValidationState={fieldValidationState}
          register={register(id, {
            maxLength: maxLength ? maxLength : undefined,
            required: required
          })}
          type="password"
          id={id}
          placeholder={placeholder}
        />
        <ErrorMessage name={id} />
      </div>
    )
  } else {
    return (
      <>
        <div>
          <Label htmlFor={id} isRequired={required}>
            {label}
          </Label>
          <InputText
            fieldValidationState={fieldValidationState}
            register={register(id, {
              maxLength: maxLength ? maxLength : undefined,
              required: required
            })}
            type="password"
            id={id}
            placeholder={placeholder}
          />
          <ErrorMessage name={id} />
        </div>
        <div>
          <Label htmlFor={`${id}-confirm`} isRequired={required}>
            {confirmLabel}
          </Label>
          <InputText
            fieldValidationState={confirmFieldValidationState}
            register={register(`${id}-confirm`, {
              maxLength: maxLength ? maxLength : undefined,
              required: required,
              validate: (value) =>
                value === getValues("password") ||
                "The passwords needs to match."
            })}
            type="password"
            id={`${id}-confirm`}
            placeholder={confirmPlaceholder}
          />
          <ErrorMessage name={`${id}-confirm`} />
        </div>
      </>
    )
  }
}
