import { useThemeSettingsGroup } from "@/components/context/theme"
import Link from "next/link"

export function Contact() {
  const { contact } = useThemeSettingsGroup()

  return (
    <div>
      <p className="mb-2 text-xl font-semibold">{contact.contactTitle}</p>
      <address className="not-italic">
        {contact.email && (
          <p className="mt-2">
            <Link href={`mailto:${contact.email}`}>
              <a className="underline">E-mail: {contact.email}</a>
            </Link>
          </p>
        )}

        {contact.phone && (
          <p className="mt-2">
            <Link href={`tel:${contact.phone.replace(/[^0-9]/g, "")}`}>
              <a className="underline">Phone: {contact.phone}</a>
            </Link>
          </p>
        )}
      </address>
    </div>
  )
}
