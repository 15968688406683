import type { TIconProps } from "./types"

export default function ChevronDownIcon({ className }: TIconProps) {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)">
        <path
          d="m8 12.45.678-.58L16 5.592 14.643 4 8 9.696 1.356 4 0 5.593l7.322 6.276.678.58Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
