import styled from "styled-components"
import tw from "twin.macro"
import cx from "classnames"
import type { ReactNode } from "react"
let paragraphStyles = tw`text-base xl:leading-7`
let preambleStyles = tw`text-lg xl:text-xl xl:leading-8`

export const componentPaddingY =
  "py-10 sm:py-12 md:py-14 lg:py-20 xl:py-[7.5rem]"

export const containerPaddingX =
  "px-[1.125rem] sm:px-12 md:px-[38px] lg:px-[3.25rem] xl:px-[4.5rem]"
import { mapTailwindColors } from "utils/mapTailwindStyles"

export let Paragraph = styled.p`
  ${paragraphStyles}
`

export let Preamble = styled.p`
  ${preambleStyles}
`

export let Content = styled.div`
  p {
    ${paragraphStyles}
  }

  .preamble {
    ${preambleStyles}
  }

  * + * {
    ${tw`mt-4`}
  }

  a {
    ${tw`underline`}
    &.inline-block,
    &.inline-flex {
      + a.inline-block,
      + a.inline-flex {
        ${tw`ml-6`}
      }
    }
  }

  hr {
    ${tw`my-8 md:my-10`}
  }
  dd {
    ${tw`ml-6`}
  }
  pre {
    ${tw`overflow-auto`}
  }

  > dl,
  pre {
    ${tw`p-6 my-8 bg-gray-100 md:my-10`}
  }

  blockquote {
    p {
      ${tw`text-[28px] text-blue`}
    }
  }

  ul {
    ${tw`px-0 list-disc list-inside`}
  }
  ol {
    ${tw`list-decimal list-inside`}
  }
  ul ul,
  ul ol,
  ol ol,
  ol ul {
    ${tw`ml-6`}
  }

  table tr:nth-child(even) {
    ${tw`bg-gray-100 `}
  }
  th,
  td {
    ${tw`p-2`}
  }

  .wp-caption {
    ${tw`max-w-full`}
    ${tw`!w-full`}
  }
  .wp-caption,
  .wp-block-image,
  .wp-block-cover {
    ${tw`my-10`}
  }
  .alignright {
    ${tw`float-right pl-4`}
  }
  .alignleft {
    ${tw`float-left pr-4`}
  }
  .aligncenter {
    ${tw`text-center`}
  }
  .wp-caption-text,
  .wp-block-cover-text {
    ${tw`text-xs`}
  }

  .wp-caption,
  .wp-block-image,
  .wp-block-cover,
  > ul,
  > ol,
  > dl,
  blockquote,
  pre,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    &:first-child {
      ${tw`mt-0`}
    }
  }
`

type ComponentProps = {
  children: ReactNode
  bgColor?: string
  className?: string
  id?: string
}

export const Component = ({
  children,
  bgColor,
  className,
  id
}: ComponentProps) => {
  const classNames = cx(
    "component relative",
    mapTailwindColors(bgColor ?? "white"),
    componentPaddingY,
    className,
    { "text-white": bgColor === "blue" }
  )

  return (
    <section id={id} className={classNames}>
      {children}
    </section>
  )
}

export const Container = styled.div(({ narrow }: { narrow?: boolean }) => [
  tw`w-full mx-auto`,
  tw`${containerPaddingX}`,
  narrow ? tw`md:max-w-[44rem] xl:max-w-[53rem]` : tw`max-w-screen-xl`
])
