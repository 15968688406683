import { useMutation } from "@apollo/client"
import { FormProvider, useForm } from "react-hook-form"
import { Button } from "../buttons"
import Link from "next/link"
import { WP_GET_USER, WP_LOG_IN } from "@/lib/graphql/mutations"
import Icon from "@/components/icons/Icon"
import cx from "classnames"
import TextField from "../fields/TextField"
import PasswordField from "../fields/PasswordField"
import { useState } from "react"
import { push } from "@socialgouv/matomo-next"

export const FormValidationBox = ({
  id,
  message
}: {
  id: string
  message: string
}) => {
  return (
    <div className="flex items-center p-4 text-sm text-red bg-red-light">
      <Icon name="error" className="inline mr-2" />
      <p id={id}>{message}</p>
    </div>
  )
}

type FormValues = {
  username: string
  password: string
}

const WPLoginForm = ({ isLarge }: { isLarge?: boolean }) => {
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false)
  const [signIn, { called, loading, error }] = useMutation(WP_LOG_IN, {
    refetchQueries: [{ query: WP_GET_USER }]
  })

  const methods = useForm<FormValues>()
  const {
    handleSubmit,
    formState: { errors }
  } = methods

  const errorMessage = error?.message || ""
  const isEmailValid =
    !errorMessage.includes("empty_email") &&
    !errorMessage.includes("empty_username") &&
    !errorMessage.includes("invalid_email") &&
    !errorMessage.includes("invalid_username")
  const isPasswordValid =
    !errorMessage.includes("empty_password") &&
    !errorMessage.includes("incorrect_password")

  const onSubmit = (formData: FormValues) => {
    const { username, password } = formData

    let isError = false

    signIn({ variables: { login: username, password } }).catch((error) => {
      console.error(error)
      isError = true
    })

    if (!isError) {
      setIsSuccessfullySubmitted(true)
      push(["trackEvent", "userLoggedIn", username])
    }
  }

  const isLoading = loading || (called && !error)

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-4">
        {!isEmailValid ? (
          <FormValidationBox
            id="email-desc"
            message="Invalid email. Please try again."
          />
        ) : null}
        {!isPasswordValid ? (
          <FormValidationBox
            id="password-desc"
            message="Invalid password. Please try again."
          />
        ) : null}
        <fieldset
          disabled={isLoading}
          aria-busy={isLoading}
          className="space-y-4">
          <TextField
            id="username"
            label="Username"
            placeholder="Username or e-mail address"
            required={true}
            maxLength={60}
            aria-invalid={errors.username ? "true" : "false"}
            aria-describedby="emailInvalid"
          />
          <PasswordField
            id="password"
            confirmPassword={false}
            label="Password"
            required={true}
            placeholder="Your password"
            aria-invalid={errors.password ? "true" : "false"}
            aria-describedby="password-desc"
          />
        </fieldset>

        <Button
          type="submit"
          className={cx("justify-center w-full min-w-[200px]", {
            "sm:w-auto": isLarge
          })}
          disabled={loading}>
          {isLoading ? "Logging in..." : "Log in"}
        </Button>

        <div className="flex justify-between font-semibold underline">
          <Link href="/forgot-password">
            <a>Forgot Password?</a>
          </Link>
          <Link href="/signup">
            <a>Register</a>
          </Link>
        </div>
      </form>
    </FormProvider>
  )
}

export default WPLoginForm
