import type { Dispatch, SetStateAction } from "react"
import type { TDialogs } from "@/components/header"

type Props = {
  isOpen: boolean
  setOpenDialog: Dispatch<SetStateAction<TDialogs>>
}

export function Overlay({ isOpen, setOpenDialog }: Props): JSX.Element {
  return (
    <div
      className={`fixed inset-0 bg-black top-header-offset transition-all cursor-default z-30 ${
        isOpen ? "bg-opacity-25" : "invisible bg-opacity-0 pointer-events-none"
      }`}
      onClick={() => setOpenDialog("")}
      tabIndex={-1}
      onKeyPress={(event) =>
        event.key === "Escape" ? setOpenDialog("") : null
      }
      role="button"></div>
  )
}
