import type { TIconProps } from "./types"

export default function ChevronUpIcon({ className }: TIconProps) {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)">
        <path
          d="m8 4-.678.58L0 10.856l1.356 1.593L8 6.754l6.643 5.695L16 10.856 8.678 4.58 8 4Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
