import type { TIconProps } from "./types"

export default function MinusIcon({ className }: TIconProps) {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)">
        <path fill="currentColor" d="M16 7v2H0V7z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
