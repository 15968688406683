import type { TIconProps } from "./types"

export default function CheckIcon({ className }: TIconProps) {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        d="M21 37.8 11.2 28l-3.267 3.267L21 44.333l28-28-3.267-3.266L21 37.8Z"
        fill="currentColor"
      />
    </svg>
  )
}
