import type { TIconProps } from "./types"

export default function PinIcon({ className }: TIconProps) {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16s6-6.686 6-10A6 6 0 0 0 2 6c0 3.314 6 10 6 10Zm0-6a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
        fill="currentColor"
      />
    </svg>
  )
}
