import { useThemeSettingsGroup } from "@/components/context/theme"
import parse from "html-react-parser"

export function Address() {
  const { postal } = useThemeSettingsGroup()

  return (
    <div>
      <p className="mb-2 text-xl font-semibold">{postal.postalTitle}</p>
      <address className="not-italic">
        {postal.postalText && (
          <div className="mt-2">{parse(postal.postalText)}</div>
        )}
      </address>
    </div>
  )
}
