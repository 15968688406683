/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { forwardRef, ReactNode } from "react"
import Link from "next/link"
import type { LinkProps } from "next/link"
import cx from "classnames"
import Icon from "../icons/Icon"
import type { TIcons } from "../icons/Icon"

export type ButtonProps = {
  children?: ReactNode
  className?: string
  iconClassName?: string
  styleType?: "light" | "blue" | "yellow" | "link" | "lightlink"
  iconName?: TIcons
  iconPosition?: "left" | "right"
}

type ButtonAsButton = ButtonProps &
  Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, keyof ButtonProps>

type ButtonAsUnstyled = Omit<ButtonAsButton, "styleType">

type ButtonAsLink = ButtonProps & Omit<LinkProps, keyof ButtonProps>

type ButtonAsExternal = ButtonProps &
  Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof ButtonProps>

const getButtonContent = (
  children?: ReactNode,
  iconName?: TIcons,
  iconPosition?: ButtonProps["iconPosition"],
  className?: string,
  unstyled?: boolean
) => {
  if (!iconName) {
    return children
  }

  const classNames = cx(
    {
      btn__icon: !unstyled,
      "ml-2.5": iconPosition === "right",
      "mr-2.5": iconPosition === "left"
    },
    className
  )

  if (iconPosition === "right") {
    return (
      <>
        {children}
        <Icon name={iconName} className={classNames} />
      </>
    )
  }

  return (
    <>
      <Icon name={iconName} className={classNames} />
      {children}
    </>
  )
}

const getButtonClassNames = (
  props: ButtonAsButton | ButtonAsExternal | ButtonAsLink
) => {
  const { styleType, iconName, iconPosition, className } = props

  const classNames = cx(
    {
      btn: styleType !== "link",
      // Button types
      "btn-yellow": !styleType, // Default style
      "btn-blue": styleType === "blue",
      "btn-lightblue": styleType === "light",
      "btn-link": styleType === "link",
      "btn-lightlink": styleType === "lightlink",
      // Button modifiers
      "btn--icon": iconName,
      "btn--icon-center": iconName && !iconPosition
    },
    className
  )

  return classNames
}

export const ButtonLink = (props: ButtonAsLink) => {
  const {
    href,
    children,
    styleType,
    iconName,
    iconPosition,
    className,
    iconClassName,
    ...rest
  } = props

  const classNames = getButtonClassNames(props)
  return (
    <Link href={href}>
      <a className={classNames} {...rest}>
        {getButtonContent(children, iconName, iconPosition, iconClassName)}
      </a>
    </Link>
  )
}

export const ButtonLinkExternal = (props: ButtonAsExternal) => {
  const {
    className,
    styleType,
    iconName,
    iconPosition,
    iconClassName,
    children,
    ...rest
  } = props

  const classNames = getButtonClassNames(props)
  return (
    <a
      className={classNames}
      target="_blank"
      rel="noopener noreferrer"
      {...rest}>
      {getButtonContent(children, iconName, iconPosition, iconClassName)}
    </a>
  )
}

export const ButtonUnstyled = forwardRef<HTMLButtonElement, ButtonAsUnstyled>(
  (props, ref) => {
    const {
      className,
      iconName,
      iconPosition,
      iconClassName,
      children,
      ...rest
    } = props
    return (
      <button className={className} {...rest} ref={ref}>
        {getButtonContent(
          children,
          iconName,
          iconPosition,
          iconClassName,
          true
        )}
      </button>
    )
  }
)

ButtonUnstyled.displayName = "ButtonUnstyled"

export const Button = forwardRef<HTMLButtonElement, ButtonAsButton>(
  (props, ref) => {
    const {
      className,
      styleType,
      iconName,
      iconPosition,
      iconClassName,
      children,
      ...rest
    } = props
    const classNames = getButtonClassNames(props)

    return (
      <button className={classNames} {...rest} ref={ref}>
        {getButtonContent(children, iconName, iconPosition, iconClassName)}
      </button>
    )
  }
)

Button.displayName = "Button"
