import { useRef } from "react"
import type {
  THierachicalMenuItem,
  THierachicalMenuItemProps,
  THierachicalMenuItemsProps,
  TSubMenuToggle
} from "@/types/graphql/menus"
import cx from "classnames"
import { useRouter } from "next/dist/client/router"
import Link from "next/link"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import type { TDialogs } from "@/components/header"
import Icon from "../icons/Icon"

type OffCanvasProps = {
  menuItems: THierachicalMenuItem[]
  secondaryMenuItems: THierachicalMenuItem[]
  isOpen: boolean
  setOpenDialog: Dispatch<SetStateAction<TDialogs>>
}

export function OffCanvas({
  menuItems,
  isOpen,
  setOpenDialog
}: OffCanvasProps) {
  const { asPath } = useRouter()

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setOpenDialog("")
  }, [asPath, setOpenDialog])

  return (
    <div
      ref={ref}
      className={`fixed flex flex-col top-header-offset right-0 z-50 w-full lg:hidden sm:max-w-md overflow-hidden bg-blue transition-transform bottom-0 overflow-y-auto ${
        isOpen ? "translate-x-0" : "translate-x-full"
      }`}
      aria-hidden={!isOpen}>
      <nav>
        <ul className="flex flex-col justify-between w-full">
          {menuItems.map((menuItem) => (
            <MenuItem key={menuItem.id} menuItem={menuItem} />
          ))}
        </ul>
      </nav>
    </div>
  )
}

function MenuItem({ menuItem }: THierachicalMenuItemProps) {
  let [subMenuIsVisible, setSubMenuIsVisible] = useState(false)

  return (
    <li className="relative flex flex-wrap items-center content-between justify-between grow group font-display border-b border-b-[#4680AC]">
      <Link href={menuItem.path}>
        <a
          target={menuItem.target}
          title={menuItem.title}
          className="flex items-center flex-1 p-6 text-xl font-medium leading-6 text-white hover:underline">
          {menuItem.label}
        </a>
      </Link>

      {menuItem?.children.length > 0 && (
        <SubMenuToggle
          handleOnToggle={setSubMenuIsVisible}
          isToggled={subMenuIsVisible}
          className=""
        />
      )}

      {menuItem.children.length > 0 && subMenuIsVisible && (
        <SubMenuDepthTwo menuItems={menuItem.children} />
      )}
    </li>
  )
}

const SubMenuToggle = ({
  handleOnToggle,
  isToggled,
  className
}: TSubMenuToggle) => {
  const classNames = cx(
    "flex items-start justify-end flex-shrink-0 pr-6",
    className
  )
  const iconClasses = cx(
    "flex-initial transform-gpu transition-transform w-[30px] h-[30px] p-[3px]",
    {
      "text-white bg-blue-550": !isToggled,
      "rotate-180 text-black bg-white": isToggled
    }
  )

  return (
    <button
      onClick={() => handleOnToggle(!isToggled)}
      className={classNames}
      aria-label={isToggled ? "Stäng undermeny" : "Öppna undermeny"}>
      <Icon name="menuArrow" className={iconClasses} />
    </button>
  )
}

function SubMenuDepthTwo({ menuItems }: THierachicalMenuItemsProps) {
  const classNames = cx(
    "flex flex-col justify-between w-full pr-6 py-6 pl-12 space-y-4 bg-blue-700"
  )
  return (
    <ul className={classNames}>
      {menuItems.map((menuItem) => (
        <MenuItemDepthTwo key={menuItem.id} menuItem={menuItem} />
      ))}
    </ul>
  )
}

function MenuItemDepthTwo({ menuItem }: THierachicalMenuItemProps) {
  const [subMenuIsVisible, setSubMenuIsVisible] = useState(false)

  return (
    <li className="relative flex flex-wrap items-center content-between justify-between grow">
      <Link href={menuItem.path}>
        <a
          target={menuItem.target}
          title={menuItem.title}
          className="flex items-center text-lg font-normal leading-6 text-white grow hover:underline font-body">
          {menuItem.label}
        </a>
      </Link>

      {menuItem?.children.length > 0 && (
        <SubMenuToggle
          handleOnToggle={setSubMenuIsVisible}
          isToggled={subMenuIsVisible}
          className="pt-0 pr-0"
        />
      )}
      {menuItem.children.length > 0 && subMenuIsVisible && (
        <SubMenuDepthThree menuItems={menuItem.children} />
      )}
    </li>
  )
}

function SubMenuDepthThree({ menuItems }: THierachicalMenuItemsProps) {
  return (
    <ul className="flex flex-col justify-between w-full mt-6 mb-2 space-y-6 border-l-4 border-l-[#567BA2]">
      {menuItems.map((menuItem) => (
        <MenuItemDepthThree key={menuItem.id} menuItem={menuItem} />
      ))}
    </ul>
  )
}

function MenuItemDepthThree({ menuItem }: THierachicalMenuItemProps) {
  const [subMenuIsVisible, setSubMenuIsVisible] = useState(false)

  return (
    <li className="relative flex flex-wrap items-center content-between justify-between grow group">
      <Link href={menuItem.path}>
        <a
          target={menuItem.target}
          title={menuItem.title}
          className="flex items-center px-4 text-base font-normal leading-5 text-white grow hover:underline">
          {menuItem.label}
        </a>
      </Link>

      {menuItem?.children.length > 0 && (
        <SubMenuToggle
          handleOnToggle={setSubMenuIsVisible}
          isToggled={subMenuIsVisible}
          small
        />
      )}
    </li>
  )
}
