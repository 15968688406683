import { useRouter } from "next/dist/client/router"
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import useOnClickOutside from "@/lib/hooks/useOnClickOutside"
import type { TDialogs } from "@/components/header"
import { ButtonUnstyled } from "../buttons"
import SearchFormComponent from "../searchFormComponent"
import cx from "classnames"

type Props = {
  isOpen: boolean
  setOpenDialog: Dispatch<SetStateAction<TDialogs>>
}

export function SearchForm({ isOpen, setOpenDialog }: Props) {
  const [search, setSearch] = useState("")
  const { push, pathname } = useRouter()
  const searchRef = useRef<HTMLInputElement>(null)

  const handleSubmit = (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault()
    push(`/search/${encodeURI(search)}`)
  }

  const handleClickOutside = () => {
    if (!isOpen) {
      return
    }
    setOpenDialog("")
  }

  const ref = useRef<HTMLDivElement>(null)

  useOnClickOutside(ref, handleClickOutside)

  useEffect(() => {
    if (isOpen) {
      const node = searchRef.current as HTMLInputElement
      node.focus()
    }
  }, [isOpen])

  useEffect(() => {
    setOpenDialog("")
  }, [pathname, setOpenDialog])

  return (
    <div ref={ref} className="relative flex items-center h-full">
      <ButtonUnstyled
        iconName="search"
        className={cx({ "text-blue": isOpen })}
        onClick={() => (isOpen ? setOpenDialog("") : setOpenDialog("search"))}>
        <span className="sr-only">
          {isOpen ? "Close search" : "Open search"}
        </span>
      </ButtonUnstyled>
      {isOpen && (
        <>
          <form
            className="fixed left-0 right-0 z-50 flex items-center top-header-offset lg:max-w-[38rem] w-full lg:left-auto"
            onSubmit={handleSubmit}>
            <SearchFormComponent
              handleOnChange={(event) => setSearch(event.target.value)}
              placeholder="Search swfbf.se"
              ref={searchRef}
            />
          </form>
        </>
      )}
    </div>
  )
}
