import type {
  THierachicalMenuItemProps,
  THierachicalMenuItemsProps
} from "@/types/graphql/menus"
import Link from "next/link"
import { useState, useCallback } from "react"
import cx from "classnames"
import { ButtonUnstyled } from "../buttons"
import { MenuIndicator } from "."

export function Menu({
  menuItems,
  setOpenDialog,
  openDialog
}: THierachicalMenuItemsProps) {
  return (
    <nav className="hidden h-full mx-6 font-bold lg:block">
      <MenuLevel
        menuItems={menuItems}
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
      />
    </nav>
  )
}

export function MenuLevel({
  menuItems,
  setOpenDialog,
  openDialog
}: THierachicalMenuItemsProps) {
  return (
    <ul className="flex h-full space-x-6 xl:space-x-9">
      {menuItems.map((menuItem) => (
        <MenuItem
          key={menuItem.id}
          menuItem={menuItem}
          setOpenDialog={setOpenDialog}
          openDialog={openDialog}
        />
      ))}
    </ul>
  )
}

export function MenuItem({
  menuItem,
  setOpenDialog,
  openDialog
}: THierachicalMenuItemProps) {
  const subMenuIsVisible = openDialog === menuItem.id

  const hasChildren = menuItem.children.length > 0

  const linkClasses = cx(
    "relative flex items-center grow h-full py-4 font-medium transition-colors hover:text-blue text-lg hover:after:opacity-100 after:block after:absolute after:opacity-0 after:transition-opacity after:top-0 after:left-0 after:right-0 after:bg-blue after:h-1",
    {
      "after:-right-4": hasChildren,
      "after:opacity-100 text-blue": subMenuIsVisible
    }
  )

  const handleOnMouseEnter = useCallback(() => {
    if (!hasChildren || menuItem.depth > 0) {
      return
    }
    setOpenDialog && setOpenDialog(menuItem.id)
  }, [hasChildren, menuItem.depth, menuItem.id, setOpenDialog])

  const handleOnMouseLeave = useCallback(() => {
    if (!hasChildren || menuItem.depth > 0) {
      return
    }
    setOpenDialog && setOpenDialog("")
  }, [hasChildren, menuItem.depth, setOpenDialog])

  return (
    <li
      className="relative flex items-center content-between justify-between h-full grow group"
      onMouseEnter={() => {
        handleOnMouseEnter()
      }}
      onMouseLeave={() => {
        handleOnMouseLeave()
      }}>
      <Link href={menuItem.path}>
        <a
          target={menuItem.target}
          title={menuItem.title}
          className={linkClasses}>
          {menuItem.label}
          {hasChildren && subMenuIsVisible && (
            <MenuIndicator className="!text-blue-700" />
          )}
        </a>
      </Link>

      {hasChildren && (
        <ButtonUnstyled
          iconName="menuArrow"
          iconClassName={cx("text-blue transform-gpu transition-transform", {
            "rotate-180": subMenuIsVisible
          })}
          onClick={() => {
            if (subMenuIsVisible) {
              setOpenDialog && setOpenDialog("")
              return
            }
            setOpenDialog && setOpenDialog(menuItem.id)
          }}
          className="flex items-center justify-center w-6 h-6 pointer-events-none">
          <span className="sr-only">
            {subMenuIsVisible ? "Close submenu" : "Open submenu"}
          </span>
        </ButtonUnstyled>
      )}
      {hasChildren && subMenuIsVisible && (
        <SubMenu menuItems={menuItem.children} />
      )}
    </li>
  )
}

export function MenuItemLevelTwo({ menuItem }: THierachicalMenuItemProps) {
  const [subMenuIsVisible, setSubMenuIsVisible] = useState(false)

  const hasChildren = menuItem.children.length > 0

  return (
    <li
      className="relative z-20 flex flex-col content-between justify-between grow group bg-blue-700"
      onMouseEnter={() => {
        if (!hasChildren || menuItem.depth > 0) {
          return
        }

        setSubMenuIsVisible(true)
      }}
      onMouseLeave={() => {
        if (!hasChildren || menuItem.depth > 0) {
          return
        }
        setSubMenuIsVisible(false)
      }}>
      <div className="flex items-center pl-12 pr-8">
        <Link href={menuItem.path}>
          <a
            target={menuItem.target}
            title={menuItem.title}
            className={cx(
              "relative flex items-center grow text-white text-lg leading-6 hover:underline h-full py-6 font-medium"
            )}>
            {menuItem.label}
          </a>
        </Link>

        {hasChildren && (
          <ButtonUnstyled
            iconName="menuArrow"
            iconClassName={cx(
              "transform-gpu w-[30px] h-[30px] transition-transform",
              {
                "text-white bg-blue-550": !subMenuIsVisible,
                "rotate-180 text-black bg-white": subMenuIsVisible
              }
            )}
            onClick={() => setSubMenuIsVisible(!subMenuIsVisible)}
            className="flex items-start justify-center px-2 py-4 ml-2 -mr-4">
            <span className="sr-only">
              {subMenuIsVisible ? "Close submenu" : "Open submenu"}
            </span>
          </ButtonUnstyled>
        )}
      </div>
      {hasChildren && subMenuIsVisible && (
        <SubMenuLevelTwo menuItems={menuItem.children} />
      )}
    </li>
  )
}

function MenuItemLevelThree({ menuItem }: THierachicalMenuItemProps) {
  return (
    <li className="relative flex flex-wrap items-center content-between justify-between grow group">
      <Link href={menuItem.path}>
        <a
          target={menuItem.target}
          title={menuItem.title}
          className="flex items-center ml-4 text-base font-normal leading-5 text-white hover:underline font-body">
          {menuItem.label}
        </a>
      </Link>
    </li>
  )
}

export function SubMenu({ menuItems }: THierachicalMenuItemsProps) {
  return (
    <ul className="overflow-y-auto bg-blue-700 absolute z-40 left-0 top-full flex flex-col justify-between w-[320px] max-h-[80vh] font-medium divide-y divide-[#0E3D6C]">
      {menuItems.map((menuItem) => (
        <MenuItemLevelTwo key={menuItem.id} menuItem={menuItem} />
      ))}
    </ul>
  )
}

export function SubMenuLevelTwo({ menuItems }: THierachicalMenuItemsProps) {
  return (
    <ul className="flex flex-col justify-between mt-3 mb-7 mx-12 border-l-4 border-l-[#567BA2] bg-blue-700 space-y-6">
      {menuItems.map((menuItem) => (
        <MenuItemLevelThree key={menuItem.id} menuItem={menuItem} />
      ))}
    </ul>
  )
}
